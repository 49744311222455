import React from 'react'
// import { graphql } from 'gatsby'
import { Heading, Stack, Box, Link, Flex } from '@pw/ui'

import { Layout, Container } from '../components/Layout'
import PostGrid from '../components/Posts/Grid'

const PostCategoryBasePageTemplate = ({ pageContext, data, location }) => {
  const seo = {}
  const { slug: landingPage = 'unkown' } = pageContext
  const posts = data?.allContentfulBlogPost?.edges || []
  const categories = data?.allContentfulPostCategory?.edges || []

  return (
    <Layout
      headerTitle={`Category`}
      location={location}
      seo={{
        ...seo,
        title: `${landingPage}`,
        description: `${landingPage}`,
      }}
    >
      <Container pt="4" py="8" px={{ base: 0, md: 4 }} maxW="1200px">
        <Heading as="h2">{landingPage}</Heading>

        <Stack spacing="20" mt="4">
          {categories.map(({ node: { slug: catSlug, title } }) => (
            <Box key={catSlug}>
              <Heading as="h3">{title}</Heading>

              <Box py="4">
                <PostGrid
                  posts={posts.filter(({ node }) =>
                    node.postCategories.map(({ slug }) => slug).includes(catSlug),
                  )}
                />
              </Box>

              <Link to={`/${catSlug}`}>View All {title} Posts</Link>
            </Box>
          ))}
        </Stack>
      </Container>
    </Layout>
  )
}

export default PostCategoryBasePageTemplate

export const pageQuery = graphql`
  query postCategoryBasePage($categories: [String!]) {
    allContentfulBlogPost(
      filter: { postCategories: { elemMatch: { slug: { in: $categories } } } }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    allContentfulPostCategory(filter: { slug: { in: $categories } }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`
